/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Logo from "../assets/logo.png"

function SEO({
  jsonLdProduct = null,
  metaImage = null,
  description = null,
  lang = `en`,
  meta = [],
  title = null,
  canonicals = null,
  canonicalUrl = null,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://teklafabrics.com${canonicalUrl}`,
        },
        {
          property: `og:image`,
          content:
            (metaImage && metaImage.startsWith("//")
              ? `https:${metaImage}`
              : metaImage) || site.siteMetadata.image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content:
            (metaImage && `https:${metaImage}`) || site.siteMetadata.image,
        },
      ].concat(meta)}
    >
      {jsonLdProduct && (
        <script type="application/ld+json">
          {`
            {
              "@context": "https://www.schema.org",
              "@type": "product",
              "brand": "Tekla Fabrics",
              "logo": "${Logo}",
              "name": "${jsonLdProduct.title}",
              "category": "${jsonLdProduct.category}",
              "image": "${jsonLdProduct.thumbnail}"
          }",
          "description": "${description}",
          "sku": "${jsonLdProduct.sku}",
          "color": "${jsonLdProduct.color}",
            }
          `}
        </script>
      )}

      {canonicals &&
        Object.entries(canonicals)?.map(([locale, path]) => {
          if (locale === "en-US") {
            return (
              <link
                hrefLang="x-default"
                href={path}
                rel="alternate"
                key={path}
              />
            )
          }

          if (locale === "de-DE") {
            return (
              <link hrefLang="de-DE" href={path} rel="alternate" key={path} />
            )
          }
        })}

      {!!canonicalUrl && <link rel="canonical" href={`https://teklafabrics.com${canonicalUrl}`} />}

      <script type="application/ld+json">
        {`
          {
            "@context" : "https://schema.org",
              "@type" : "Organization",
              "name" : "Tekla Fabrics",
              "url" : "https://teklafabrics.com",
              "sameAs" : [
                "https://facebook.com/teklafabrics",
                "https://pinterest.dk/teklafabrics",
                "https://instagram.com/teklafabrics",
                "https://open.spotify.com/user/bj3q21zapzgtcl03gy9o7x2of?si=ON0IdDyLTb6ZmXmyAOQl5w"
              ]
          }
        `}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
